<template>
  <select class="custom-select" v-model="valueChild">
    <option :value="undefined">TODOS</option>
    <option :value="0">ABIERTOS</option>
    <option :value="1">CERRADOS</option>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style>
</style>
